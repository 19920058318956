// Global settings for the page header

.page-header {
    .page-header-content {
        padding-top: 3rem;
        padding-bottom: 3rem;
        .page-header-title {
            line-height: normal;
            font-size: 2.25rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            .page-header-icon {
                margin-right: 0.5rem;
                display: inline-flex;
                .feather {
                    height: 1.75rem;
                    width: 1.75rem;
                }
            }
        }
        // Base styling for the page header subtitle
        .page-header-subtitle {
            font-size: 1.15rem;
            margin-top: 0.25rem;
        }
    }
    .page-header-search {
        .input-group-joined {
            border-color: transparent;
            .form-control {
                height: 4rem;
                font-size: 1.25rem;
            }
            &:focus-within {
                box-shadow: $input-focus-box-shadow;
                border-color: $input-focus-border-color;
            }
        }
    }
}
