.video {
    overflow: hidden;
    .video-link {
        position: relative;
        display: flex;
        width: 100%;
        padding: 10rem 1rem;
        align-items: center;
        justify-content: center;
        color: fade-out($white, 0.5);
        transition: color 0.2s ease-in-out;
        svg {
            height: 3rem;
            width: 3rem;
        }
        &:hover {
            color: $white;
        }
    }
}