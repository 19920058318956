.dropdown-user {
    .dropdown-menu {
        min-width: 13rem;
        .dropdown-header {
            text-transform: none;
            letter-spacing: normal;
            .dropdown-user-img {
                height: 2.5rem;
                width: 2.5rem;
                margin-right: 1rem;
                border-radius: 100%;
            }
            .dropdown-user-details {
                font-weight: 400;
                .dropdown-user-details-name {
                    color: $gray-900;
                    font-weight: 500;
                    font-size: 0.9rem;
                    max-width: 10rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .dropdown-user-details-email {
                    color: $gray-600;
                    font-size: 0.75rem;
                    max-width: 10rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .dropdown-item {
            align-items: center;
            .dropdown-item-icon {
                color: $gray-500;
                svg {
                    vertical-align: inherit;
                }
            }
            &:active .dropdown-item-icon {
                color: $white;
            }
        }
    }
}
