// Override Bootstrap custom control styles

.custom-control {
    label {
        cursor: pointer;
        margin: 0.25rem 0;
        &:hover {
            &:before {
                background-color: $gray-200;
            }
        }
    }
    .custom-control-input:disabled:checked ~ .custom-control-label::before {
        background-color: $gray-200;
        border-color: $gray-300;
    }
    .custom-control-input:disabled ~ .custom-control-label {
        color: $gray-500;
        cursor: not-allowed;
    }
}

// Custom control style - solid
//
// Append the .custon-control-solid class to any .custom-control element
// to get a custom control style - works for radios and checkboxes

.custom-control-solid {
    label:before {
        background-color: $input-solid-bg;
        border-color: $input-solid-border-color;
    }
    label:hover {
        &:before {
            background-color: darken($input-solid-bg, 10%);
            border-color: darken($input-solid-border-color, 10%);
        }
    }
    .custom-control-input:disabled ~ .custom-control-label::before {
        background-color: lighten($input-solid-bg, 3%);
        border-color: lighten($input-solid-border-color, 3%);
    }
    .custom-control-input:disabled:checked ~ .custom-control-label::before {
        background-color: lighten($input-solid-bg, 3%);
        border-color: lighten($input-solid-border-color, 3%);
    }
}
