.btn-xs {
    padding: $btn-padding-y-xs $btn-padding-x-xs;
    font-size: $btn-font-size-xs;
    border-radius: $btn-border-radius-xs;
    line-height: $line-height-xs;
}

.btn-xl {
    padding: $btn-padding-y-xl $btn-padding-x-xl;
    font-size: $btn-font-size-xl;
    border-radius: $btn-border-radius-xl;
}
