// Card header with icons

.card-header-actions {
    .card-header {
        height: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 0.5625rem;
        padding-bottom: 0.5625rem;
        .dropdown-menu {
            margin-top: 0;
            top: 0.5625rem !important;
        }
    }
}
