// Collapsable Card Styling
.card-collapsable {
    .card-header[data-toggle='collapse'] {
        display: flex;
        text-decoration: none;
        position: relative;
        align-items: center;
        justify-content: space-between;
        .card-collapsable-arrow {
            height: 0.9rem;
            width: 0.9rem;
            display: inline-flex;
            font-size: 0.9rem;
            transition: transform 0.1s ease-in-out;
            svg,
            i,
            .feather {
                height: 0.9rem;
                width: 0.9rem;
            }
        }
        &.collapsed {
            border-radius: $card-border-radius;
            .card-collapsable-arrow {
                transform: rotate(-90deg);
            }
        }
    }
}
