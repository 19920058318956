.badge-transparent-light {
    color: fade-out($white, 0.5);
    background-color: fade-out($black, 0.9);
}

.badge-transparent-dark {
    color: fade-out($gray-900, 0.5);
    background-color: fade-out($gray-900, 0.2);
}

.badge-marketing {
    padding: 0.5em 1em;
}

.badge-sm {
    font-size: 0.75rem;
}
.badge-md {
    font-size: 1rem;
}
.badge-lg {
    font-size: 1.25rem; 
}