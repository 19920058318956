// This file is intentionally blank
// Use this file to override SCSS within the project, or to add new SCSS for creating your own CSS components


.btn-ow {
  color: #fff;
  background-color: #ee2c36;
  border-color: #ee2c36;
}

.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-details .dropdown-user-details-name,
.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-details .dropdown-user-details-email
{
  max-width: 12rem;
}
