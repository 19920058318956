.invoice {
    .invoice-brand-img {
        height: 5rem;
        width: 5rem;
    }
    .table tbody tr td {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
}
