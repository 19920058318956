// Set styling for feather icons

// For usage with Angular Feather
i-feather {
    display: inline-flex !important;
    svg {
        height: 100%;
        width: 100%;
        vertical-align: top;
    }
}

// Default Styling for HTML/CSS Feather
.feather {
    height: 1rem;
    width: 1rem;
    vertical-align: top;
}

.feather-sm {
    height: 0.8rem;
    width: 0.8rem;
}

.icon-stack {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    height: 2.5rem;
    width: 2.5rem;
    font-size: 1rem;
    background-color: $gray-100;
    svg {
        height: 1rem;
        width: 1rem;
    }
}

.icon-stack-sm {
    height: 2rem;
    width: 2rem;
}

.icon-stack-lg {
    height: 4rem;
    width: 4rem;
    font-size: 1.5rem;
    svg {
        height: 1.5rem;
        width: 1.5rem;
    }
}

.icon-stack-xl {
    height: 5rem;
    width: 5rem;
    font-size: 1.75rem;
    svg {
        height: 1.75rem;
        width: 1.75rem;
    }
}

.icon-list-social {
    display: flex;
    .icon-list-social-link {
        font-size: 1.25rem;
        margin-right: 0.5rem;
    }
}