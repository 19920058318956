.card-waves,
.card-angles {
    .card-body {
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center bottom;
    }
}

.card-waves {
    .card-body {
        background-image: url("/assets/img/backgrounds/bg-waves.svg");
    }
}

.card-angles {
    .card-body {
        background-image: url("/assets/img/backgrounds/bg-angles.svg");
    }
}
