.shadow-right-sm {
    box-shadow: $box-shadow-right-sm;
}

.shadow-right {
    box-shadow: $box-shadow-right;
}

.shadow-right-lg {
    box-shadow: $box-shadow-right-lg;
}

.shadow-left-sm {
    box-shadow: $box-shadow-left-sm;
}

.shadow-left {
    box-shadow: $box-shadow-left;
}

.shadow-left-lg {
    box-shadow: $box-shadow-left-lg;
}
