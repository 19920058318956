// This file is intentionally blank
// Use this file to override any color variables within the project
// You can also replace this file with a file exported from the customizer, which you can access from the widget at https://themes.startbootstrap.com/sb-admin-pro/

$primary: #1da1f5;
$secondary: #8039da;
$success: #12ac70;
$danger: #e83d2c;
$warning: #ffc147;
$info: #2ccfd4;
